@import "../../data/styles.css";

.contact-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
	position: relative;
	z-index: 999;
}

.contact-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.contact-title {
	font-family: var(--title-font) !important;
	font-weight: var(--title-font-weight) !important;
	font-style: var(--title-font-style) !important;
}

.contact-subtitle {
	width: 100% !important;
	font-family: var(--body-font) !important;
	font-weight: var(--body-font-weight) !important;
	font-style: var(--body-font-style) !important;
}

.contact-container {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	margin-top: 120px;
}

.socials-container {
	display: flex;
	flex-direction: column;
}

.contact-socials {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-family: var(--body-font) !important;
	font-weight: var(--body-font-weight) !important;
	font-style: var(--body-font-style) !important;
}

@media (max-width: 760px) {
	.socials-container {
		margin-top: 20px;
	}
}
