@import "../../../data/styles.css";

.project {
	mix-blend-mode: normal;
	border-radius: 20px;
	opacity: 0.8;
	height: 100%;
	width: 100%;
}

.project a {
	text-decoration: none;
	width: 100%;
}

.project:hover {
	background: #f6f6f6;
	opacity: 1;
	transition: background-color 0.5s ease-in-out;
}

.project:hover .project-link {
	color: var(--link-color);
	transition: color 0.3s ease-in-out;
}

.project-container {
	padding: 30px;
}

.project-container-small {
	padding: 15px;
}

.project-container,
.project-container-small {
	margin: 0;
	width: 100%;
}

.project-container > .row,
.project-container-small > .row {
	margin: 0;
	width: 100%;
}

.project-logo {
	width: 30px;
}

.project-logo img {
	width: 100%;
}

.project-title {
	font-family: var(--secondary-font);
	color: var(--primary-color);
	font-size: 16px;
	padding-top: 15px;
	margin-bottom: 1em;
	font-family: var(--title-font) !important;
	font-weight: var(--title-font-weight) !important;
	font-style: var(--title-font-style) !important;
}

.project-description {
	color: var(--secondary-color);
	font-family: var(--body-font) !important;
	font-weight: var(--body-font-weight) !important;
	font-style: var(--body-font-style) !important;
}

.project-link {
	display: flex;
	align-items: center;
	color: var(--secondary-color);
	font-size: 12px;
	padding-top: 20px;
}

.project-link-icon {
	padding-left: 5px;
	font-size: 13px;
}

.project-link-text {
	padding-left: 20px;
	font-weight: 700;
}
