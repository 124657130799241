@import "../../data/styles.css";

.showcase-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.showcase-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
	z-index: 999;
}

.showcase-container {
	padding-top: 30px;
}
.showcase-wrapper {
	width: 100%;
	padding-top: 30px;
}
