@import "../../../data/styles.css";

.works-body {
	margin-bottom: 10px;
}

.work {
	display: flex;
	padding-bottom: 40px;
	width: 100%;
}

.work-image-container {
	display: flex;
	align-items: flex-start;
	justify-content: center;
	padding: 0;
}

.work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 15px;
	color: var(--secondary-color);
	margin-bottom: 4px;
	font-family: var(--title-font) !important;
	font-weight: var(--title-font-weight) !important;
	font-style: var(--title-font-style) !important;
}

.work-subtitle {
	font-size: 12px;
	color: var(--secondary-color);
	font-family: var(--body-font) !important;
	font-weight: var(--body-font-weight) !important;
	font-style: var(--body-font-style) !important;
}

.work-duration {
	font-size: 12px;
	color: var(--tertiary-color);
	font-family: var(--body-font) !important;
	font-weight: var(--body-font-weight) !important;
	font-style: var(--body-font-style) !important;
}

@media (max-width: 1024px) {
	.work-image-container {
		align-items: center;
	}

	.work-image {
		height: 40px;
		width: 40px;
		border-radius: 50%;
		outline: 6px solid white;
		box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
	}
}

@media (max-width: 768px) {
	.work-duration {
		text-align: left;
		margin-bottom: 4px;
	}

	.work-header {
		flex-direction: column;
	}
}

@media (min-width: 769px) {
	.work-duration {
		text-align: right;
	}
}

@media (max-width: 412px) {
	.work-image-container {
		padding-left: 0px !important;
	}
}

@media (max-width: 390px) {
	.work-image-container {
		padding-left: 0px !important;
	}

	.work-image {
		height: 30px;
		width: 30px;
	}
}
