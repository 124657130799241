@import "../../../data/styles.css";

.progress-bar-container {
	width: 100%;
}

.progress-bar-title {
	display: flex;
	align-items: center;
	margin-top: 0.5rem;
	font-size: 1.055rem;
	font-family: var(--title-font) !important;
	font-weight: var(--title-font-weight) !important;
	font-style: var(--title-font-style) !important;
}

.title-icon {
	font-size: 1.2em;
}

.progress-bar-boxes {
	display: flex;
	gap: 0.25rem;
}

.progress-box-container {
	display: grid;
	height: 0.9rem;
	flex: 1 1 0%;
	border-radius: 0.25rem;
}

.progress-box {
	height: 0.9rem;
	flex: 1 1 0%;
	border-radius: 0.25rem;
	transform-origin: center;
}

.progress-box-filled {
	grid-row: 1;
	grid-column: 1;
	background-color: var(--link-color);
	transition: all 0.5s ease-out;
	z-index: 1;
}

.progress-box-empty {
	grid-row: 1;
	grid-column: 1;
	background-color: #e5e7eb;
}

.progress-text {
	font-size: 1rem;
	padding-left: 20px;
	color: var(--secondary-color);
	font-family: var(--title-font) !important;
	font-weight: var(--title-font-weight) !important;
	font-style: var(--title-font-style) !important;
}

.progress-text .filled {
	color: var(--link-color) !important;
}
