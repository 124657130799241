@import "../../data/styles.css";

.homepage-logo-container {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 130px;
}
.homepage-container {
	padding-top: 25px;
}
.homepage-first-area {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.homepage-first-area-left-side {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}
.homepage-title {
	width: 85% !important;
	font-family: var(--title-font) !important;
	font-weight: var(--title-font-weight) !important;
	font-style: var(--title-font-style) !important;
}
.homepage-subtitle {
	width: 85% !important;
	font-family: var(--body-font) !important;
	font-weight: var(--body-font-weight) !important;
	font-style: var(--body-font-style) !important;
}
.homepage-first-area-right-side {
	display: flex;
	align-items: center;
}
.homepage-image-container {
	width: 370px;
	height: 370px;
	max-width: 500px;
	position: relative;
	margin-bottom: 20px;
}
.homepage-image-wrapper {
	position: absolute;
	overflow: hidden;
	border-radius: 10%;
	transform: rotate(3deg);
}
.homepage-image-wrapper img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	display: block;
}
.homepage-socials {
	display: flex;
	justify-content: left;
	align-items: center;
	padding-top: 30px;
}
.homepage-social-icon {
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;
	color: var(--secondary-color);
}
.homepage-social-icon:hover {
	color: var(--primary-color);
}
.homepage-after-title {
	display: flex;
}
.homepage-projects {
	flex-basis: 300px;
	padding-top: 40px;
}
.homepage-languages {
	flex-basis: 600px;
	padding-top: 30px;
	padding-right: 10px;
	height: fit-content;
}
.homepage-articles {
	flex-basis: 900px;
	padding-top: 30px;
	margin-left: -30px;
}
.homepage-article {
	padding-bottom: 5px;
}
.homepage-works {
	flex-basis: 600px;
	padding-top: 30px;
	padding-left: 10px;
}

@media (max-width: 1024px) {
	.homepage-first-area {
		flex-direction: column;
	}
	.homepage-first-area-left-side {
		display: flex;
		flex-direction: column; /* Changed: set direction to column */
		align-items: flex-start;
		width: 100%;
		order: 2;
	}
	.homepage-title {
		width: 100% !important;
		margin-top: 20px;
		order: 2;
	}
	.homepage-subtitle {
		width: 100% !important;
		margin-top: 10px;
		order: 3;
	}
	.homepage-first-area-right-side {
		width: 100%;
		order: 1;
	}
	.homepage-image-container {
		padding-top: 50px;
		padding-bottom: 50px;
		order: 1;
	}
	.homepage-after-title {
		flex-direction: column;
	}
	.homepage-projects {
		flex-basis: auto;
	}
	.homepage-articles {
		flex-basis: auto;
	}
	.homepage-works {
		flex-basis: auto;
		padding: 0px;
		padding-top: 30px;
	}
	.homepage-languages {
		flex-basis: auto;
		padding: 0px;
	}
}

@media (max-width: 1022px) {
	.homepage-logo-container {
		padding-top: 0px;
	}
	.homepage-image-container {
		width: 270px;
		height: 270px;
	}
	.homepage-title {
		padding-top: 80px;
	}
}

@media (max-width: 600px) {
	.homepage-image-wrapper {
		transform: rotate(0deg);
		width: 85vw;
		margin-top: 7vh;
	}
}

@media (max-width: 440px) {
	.homepage-first-area-right-side {
		display: flex;
		justify-content: center;
		width: 100vw;
	}
	.homepage-first-area-left-side {
		margin-top: 45vh;
	}
}
