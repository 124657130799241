:root {
	/* ------- colors ------- */
	--primary-color: #27272a;
	--secondary-color: #65656d;
	--tertiary-color: #acacb4;
	--quaternary-color: #e4e4e7;
	--link-color: #14b8a6;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;

	--title-font: "Sofia Sans", serif;
	--title-font-weight: 800;
	--title-font-style: bolder;

	--body-font: "Lato", serif;
	--body-font-weight: 400;
	--body-font-style: normal;
	/* --------------------- */
}

html,
body {
	max-width: 100%;
	overflow-x: hidden;
	background-color: #fdfdfd;
}
